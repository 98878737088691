.leaflet-container {
  width: 100%;
  height: 100%;
}

.Toastify__toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
}

.Toastify__toast {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  margin: 10px;
}

.PhoneInputInput {
  background-color: transparent;
}

.PhoneInputInput {
  background-color: transparent;
}

.PhoneInputCountrySelect {
  background-color: #71717a;
  opacity: 0.8;
}

.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #fbbf24;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #fbbf24;
}

.navbar_background_color {
  background-color: rgb(56 55 55 / var(--tw-bg-opacity));
  min-width: 100% !important;
  padding: 0px 25px;
}

.border-top-left-radius {
  border-top-left-radius: 8px;
}

.border-top-right-radius {
  border-top-right-radius: 8px;
}

.border-top-left-radius-none {
  border-top-left-radius: 0px;
}

.floating-forms {
  margin: 0px auto;
  max-width: 800px;
}

.floating-forms-2 {
  margin-top: -120px;
}

.agents-forms-2 {
  /* margin-top: -30px; */
  background-color: #fff;
  border-radius: 15px;
  margin: -30px auto 0px auto;
  max-width: 800px;
}

.button-style-inset{
  box-shadow: inset 0 1px 4px -1px #000000;
}

.button-style-outset{
  box-shadow: 0 1px 2px -1px #000000;
}
.button-style-progress-button{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
/* .floating-button-margin-top{
  margin: 5px;
} */


@media screen and (max-width: 1022px) {
  .navbar_background_color {
    padding: 6px 40px;
  }
}

@media screen and (max-width: 519px) {
  .floating-button-margin-top {
    margin: 20px !important;
  }
  .navbar_background_color {
    padding: 6px 15px;
  }
}